import { HttpErrorResponse, HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { catchError } from 'rxjs/operators';
import { throwError, Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { routes, messageError } from '../../constants';

const SER_URL = environment.apiUrl + routes.HistoricalURL;

@Injectable()
export class HistoricalService {
  constructor(private http: HttpClient) {}

  private handleError(error: HttpErrorResponse) {
    let errorMsg: string;
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error ocurred. Handle it acording to rules.
    } else {
      if (error.error.code) {
        if (messageError[error.error.code]) {
          errorMsg = messageError[error.error.code];
        } else {
          errorMsg = 'Error no registrado';
        }
      } else {
        errorMsg = error.error;
      }
    }

    return throwError(errorMsg);
  }

  public getHistoricalByPeriod(
    startDate: string,
    endDate: string,
  ): Observable<any> {
    return this.http
      .get<any>(SER_URL + 'endDateFrom=' + startDate + '&endDateTo=' + endDate)
      .pipe(catchError(this.handleError));
  }

  public getHistoricalByDeparture(departure: string): Observable<any> {
    return this.http
      .get<any>(SER_URL + 'departure=' + departure)
      .pipe(catchError(this.handleError));
  }
}
