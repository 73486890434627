import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-not-dependency',
  templateUrl: './not-dependency.component.html',
  styleUrls: ['./not-dependency.component.scss'],
})
export class NotDependencyComponent implements OnInit {
  constructor(private router: Router) {}

  ngOnInit(): void {}

  public tohome() {
    this.router.navigateByUrl('/home');
  }
}
