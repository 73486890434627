import { Injectable } from '@angular/core';
import {
  AUTH_PERMISSIONS,
  CATALOG_PERMISSIONS,
  LOGISTIC_PERMISSIONS,
  TECHNICAL_PERMISSIONS,
  WAREHOUSE_PERMISSIONS,
} from '../../../../constants/permissions';

export interface NavigationItem {
  id: string;
  title: string;
  type: 'item' | 'collapse' | 'group';
  translate?: string;
  icon?: string;
  hidden?: boolean;
  url?: string;
  classes?: string;
  exactMatch?: boolean;
  external?: boolean;
  target?: boolean;
  breadcrumbs?: boolean;
  function?: any;
  permission?: string[];
  badge?: {
    title?: string;
    type?: string;
  };
  children?: Navigation[];
}

export interface Navigation extends NavigationItem {
  children?: NavigationItem[];
}

const NavigationItems = [
  {
    id: 'other',
    title: 'Navegación',
    type: 'group',
    icon: 'feather icon-align-left',
    children: [
      {
        id: 'home',
        title: 'Inicio',
        type: 'item',
        url: '/home',
        classes: 'nav-item',
        icon: 'feather icon-home',
      },
      {
        id: 'assets',
        title: 'Almacen',
        type: 'collapse',
        icon: 'fas fa-boxes',
        permission: WAREHOUSE_PERMISSIONS,
        children: [
          // {
          //   id: 'asset-dashboard',
          //   title: 'Dashboard de Activos',
          //   type: 'item',
          //   url: '/asset/asset-dashboard',
          //   permission: ['warehouse__fridge__dashboard'],
          // },
          {
            id: 'asset-search',
            title: 'Búsqueda de Activo',
            type: 'item',
            url: '/asset/search',
            permission: ['warehouse__fridge__search'],
          },
          // {
          //   id: 'asset-warehouse',
          //   title: 'Almacén de Activos',
          //   type: 'item',
          //   url: '/asset/asset-warehouse',
          //   permission: ['warehouse__fridge__stock'],
          // },
        ],
      },
      {
        id: 'catalogs',
        title: 'Catalogos',
        type: 'collapse',
        icon: 'feather icon-list',
        permission: CATALOG_PERMISSIONS,
        children: [
          {
            id: 'catalogs-actions',
            title: 'Acciones',
            type: 'item',
            url: '/catalog/actions',
            permission: ['show__actions__module'],
          },
          {
            id: 'catalogs-agencies',
            title: 'Agencias',
            type: 'item',
            url: '/catalog/agencies',
            permission: ['show__agencies__module'],
          },
          {
            id: 'catalogs-clients',
            title: 'Clientes',
            type: 'item',
            url: '/catalog/clients',
            permission: ['show__clients__module'],
          },
          {
            id: 'catalogs-status',
            title: 'Estatus Globales',
            type: 'item',
            url: '/catalog/status',
            permission: ['show__status__module'],
          },
          {
            id: 'catalogs-internal-status',
            title: 'Estatus Internos',
            type: 'item',
            url: '/catalog/internal-status',
            permission: ['show__internalStatus__module'],
          },
          {
            id: 'catalogs-stages',
            title: 'Etapas',
            type: 'item',
            url: '/catalog/stages',
            permission: ['show__stages__module'],
          },
          {
            id: 'catalogs-logistic-workflow',
            title: 'Flujo de Logistica',
            type: 'item',
            url: '/catalog/logistic-workflow',
            permission: ['show__logisticWorkflow__module'],
          },
          // {
          //   id: 'catalogs-production-workflow',
          //   title: 'Flujo de Producción',
          //   type: 'item',
          //   url: '/catalog/production-workflow',
          // permission: ['catalogs-production-workflow'],
          // },
          {
            id: 'catalogs-transport-line',
            title: 'Linea de Transporte',
            type: 'item',
            url: '/catalog/transport-line',
            permission: ['show__transportLine__module'],
          },
          {
            id: 'catalogs-asset-brand',
            title: 'Marca de Equipo',
            type: 'item',
            url: '/catalog/asset-brand',
            permission: ['show__fridgebrands__module'],
          },
          {
            id: 'catalogs-asset-model',
            title: 'Modelo de Equipo',
            type: 'item',
            url: '/catalog/asset-model',
            permission: ['show__fridgemodels__module'],
          },
          {
            id: 'catalogs-transport-driver',
            title: 'Operador de Transporte',
            type: 'item',
            url: '/catalog/transport-driver',
            permission: ['show__transportDriver__module'],
          },
          {
            id: 'catalogs-subsidiaries',
            title: 'Sucursales',
            type: 'item',
            url: '/catalog/subsidiaries',
            permission: ['show__subsidiaries__module'],
          },
          {
            id: 'catalogs-asset-kind',
            title: 'Tipo de Equipo',
            type: 'item',
            url: '/catalog/asset-kind',
            permission: ['show__equipmentkinds__module'],
          },

          {
            id: 'catalogs-transport-kind',
            title: 'Tipo de Transporte',
            type: 'item',
            url: '/catalog/transport-kind',
            permission: ['show__transportKind__module'],
          },
        ],
      },
      {
        id: 'logistic',
        title: 'Logistica',
        type: 'collapse',
        icon: 'fa fa-route',
        permission: LOGISTIC_PERMISSIONS,
        children: [
          {
            id: 'logistic-arrivals',
            title: 'Entradas de Activos',
            type: 'item',
            url: '/logistic/arrivals',
            permission: ['logistic__service__arrival'],
          },
          {
            id: 'logistic-arrivals',
            title: 'Salidas de Activos',
            type: 'item',
            url: '/logistic/departures',
            permission: ['logistic__service__departure'],
          },
          {
            id: 'logistic-registry',
            title: 'Registro de Movimientos',
            type: 'item',
            url: '/logistic/registry',
            permission: ['logistic__service__registry'],
          },
          {
            id: 'logistic-tracking',
            title: 'Trazabilidad de Activos',
            type: 'item',
            url: '/logistic/tracking',
            permission: ['logistic__service__traceability'],
          },
        ],
      },
      {
        id: 'services',
        title: 'Servicio Técnico',
        type: 'collapse',
        icon: 'fas fa-cogs',
        permission: TECHNICAL_PERMISSIONS,
        children: [
          {
            id: 'services-search',
            title: 'Registros de Servicios Técnicos',
            type: 'item',
            url: '/technical-service/registry',
            permission: ['technical__service__kardex'],
          },
          {
            id: 'services-reports',
            title: 'Reportes de Servicio Técnico',
            type: 'item',
            url: '/technical-service/reports',
            permission: ['technical__service__reports'],
          },
        ],
      },
      {
        id: 'auth',
        title: 'Usuarios',
        type: 'collapse',
        icon: 'fa fa-users',
        permission: AUTH_PERMISSIONS,
        children: [
          {
            id: 'profile-item-create',
            title: 'Creación de Perfiles',
            type: 'item',
            url: '/auth/profile-create',
            permission: ['profile__creation__module'],
          },
          {
            id: 'users-profiles',
            title: 'Perfiles',
            type: 'item',
            url: '/auth/profiles',
            permission: ['profile__show__module'],
          },
          {
            id: 'permissions',
            title: 'Permisos',
            type: 'item',
            url: '/auth/permissions',
            permission: ['permissions__show__module'],
          },
          {
            id: 'users-create',
            title: 'Registro de Usuarios',
            type: 'item',
            url: '/auth/user-create',
            permission: ['users__creation__module'],
          },
          {
            id: 'users-item-info',
            title: 'Usuarios',
            type: 'item',
            url: '/auth/users-settings',
            permission: ['users__show__module'],
          },
        ],
      },
    ],
  },
];

@Injectable()
export class NavigationItem {
  public get() {
    return NavigationItems;
  }
}
