export const chartCategoriesTotalStatus = [
  ['1.0', 'Listo para', 'Mercado'],
  ['1.1', 'Incompleto'],
  ['3.0', 'Reparación'],
  ['3.1', 'Pinchado'],
  ['3.2', 'Diagnostico'],
  ['3.3', 'Menor'],
  ['3.4', 'Mayor'],
  ['3.5', 'Sin Gas'],
  ['3.6', 'Por', 'Presurizar'],
  ['3.7', 'Presurizado'],
  ['3.8', 'Fuga de', 'Condensador'],
  ['3.9', 'Fuga de', 'Evaporador'],
  ['3.10', 'Mayor', 'Presurizado'],
  ['3.11', 'Control de', 'Calidad'],
  ['3.12', 'Carritos Para', 'Reparación'],
  ['3.13', 'Refurbish'],
  ['3.15', 'Super'],
  ['4.0', 'Obsoleto'],
  ['7.0', 'Garantía'],
  ['8.0', 'Obsoleto por', 'Aprobar'],
  ['12.0', 'Garantía', 'Proveedor'],
];

export const chartLabelColorTotalStatus = [
  '#000',
  '#000',
  '#000',
  '#000',
  '#000',
  '#000',
  '#000',
  '#000',
  '#000',
  '#000',
  '#000',
  '#000',
  '#000',
  '#000',
  '#000',
  '#000',
  '#000',
  '#000',
  '#000',
  '#000',
  '#000',
];

export const chartColorKindStatus = [
  '#78909c',
  '#bdbdbd',
  '#ea80fc',
  '#ff5252',
  '#3d5afe',
  '#2979ff',
  '#00b0ff',
  '#00e5ff',
  '#1de9b6',
  '#00e876',
  '#dce775',
  '#ffea00',
  '#ffc400',
  '#ff9100',
];

export const chartCategoriesKindStatus = [
  ['AT1'],
  ['AT2'],
  ['AT3'],
  ['AT4'],
  ['Visimax'],
  ['Tapa de', 'Cofre'],
  ['Counter', 'Top'],
  ['Vertical'],
  ['170'],
  ['Bicicleta'],
  ['Jamaica'],
  ['Carrito'],
  ['Kiosco'],
  ['Vitrina'],
];

export const chartLabelColorKindStatus = [
  '#000',
  '#000',
  '#000',
  '#000',
  '#000',
  '#000',
  '#000',
  '#000',
  '#000',
  '#000',
  '#000',
  '#000',
  '#000',
  '#000',
  '#000',
  '#000',
  '#000',
  '#000',
  '#000',
  '#000',
  '#000',
];
