//? *************************** DEPENDENCIES ***********************************/

import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'suspensive',
})
export class SuspensivePipe implements PipeTransform {
  transform(value: string, size: number = 30): string {
    return value.length > size ? `${value.substr(0, size)}...` : value;
  }
}
