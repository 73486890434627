//? *************************** DEPENDENCIES ***********************************/
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { NgxPermissionsService } from 'ngx-permissions';
import { MessageService } from 'primeng/api';
import { Observable, of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';

//? *************************** INTERFACES *************************************/
import { AuthResponse, Profile } from '../interfaces/';

//? ***************************** CONSTANTS ************************************/
import { environment } from '../../environments/environment';
import { messageError, routes } from '../constants';
const REFRESH__TOKEN__URL = environment.apiUrl + routes.REFRESH__TOKEN__URL;
const LOGIN__URL = environment.apiUrl + routes.LOGIN__URL;

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  //? **************************** CONSTANTS ***********************************/
  private _user: Profile;

  //? **************************** LIFECYCLE ***********************************/

  constructor(
    private _httpClient: HttpClient,
    private _permService: NgxPermissionsService,
    private _messageService: MessageService,
  ) {}

  //? *************************** FUNCTIONS ***********************************/

  //****************************** HTTP REQUESTS ***********************************/

  login = (username: string, password: string): Observable<boolean> => {
    const user = { username, password };
    return this._httpClient.post<AuthResponse>(LOGIN__URL, user).pipe(
      tap(this.getUserInfo),
      map((resp) => resp.access_token),
      map((resp) => (resp ? true : false)),
      catchError(this.showError),
    );
  };

  tokenValidation = (): Observable<boolean> => {
    return this._httpClient.get<AuthResponse>(REFRESH__TOKEN__URL).pipe(
      tap(this.getUserInfo),
      map((resp) => (resp ? true : false)),
      catchError(this.showError),
    );
  };

  //*************************** HELPER FUNCTIONS **********************************/

  getUserInfo = (info: AuthResponse): void => {
    const { person, access_token } = info;
    if (!access_token) return;
    localStorage.setItem('token', access_token);
    this._user = person;
    this._permService.loadPermissions(person.permissions);
  };

  //*************************** ERROR MANAGEMENT ***********************************/

  showError = (err: HttpErrorResponse): Observable<boolean> => {
    const { error } = err;
    if (!error.code) {
      this.toastError(error); /** Backend Error */
      return of(false);
    }
    const { code } = error;
    if (!messageError[code]) {
      this.toastError(); /** Unkown Error */
      return of(false);
    }
    const msg = messageError[code]; /** Constants Error */
    this.toastError(msg);
    return of(false);
  };

  toastError = (summary: string = 'Error Desconocido'): void => {
    this._messageService.add({ severity: 'error', summary });
  };

  //******************************* LOG OUT ****************************************/

  logOut = (): void => localStorage.clear();

  //******************************** GETTERS ***************************************/

  get user(): Profile {
    return { ...this._user };
  }
}
