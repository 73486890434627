import {
  HttpErrorResponse,
  HttpClient,
  HttpHeaders,
  HttpParams,
} from '@angular/common/http';
import { catchError } from 'rxjs/operators';
import { throwError } from 'rxjs';
import { Injectable } from '@angular/core';
import { routes } from '../../constants';
import { environment } from 'src/environments/environment';

const SUBSIDIARY_STOCK = environment.apiUrl + routes.StockURL;
const ALL_STOCK_URL = environment.apiUrl + routes.AllStockURL;
const STATUS_STOCK = environment.apiUrl + routes.StatusStock;
const STATUSINTERNAL_STOCK = environment.apiUrl + routes.InternalStatusStock;
const SUBSIDIARY = environment.apiUrl + '/warehouse/fridge/abstractstock/';
const CODE = environment.apiUrl + '/warehouse/fridge/codestock/';

const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json',
    Authorization: 'my-auth-token',
  }),
};

@Injectable()
export class StockService {
  constructor(private http: HttpClient) {}

  private handleError(error: HttpErrorResponse) {
    let errorMsg;
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error ocurred. Handle it acording to rules.
    } else {
      // The backend returned an unsuccessful response code.
    }
    if (error.error) {
      if (error.error.code) {
        errorMsg = error.error.code;
      } else {
        errorMsg = error.error;
      }
      console.log(error);
    }
    return throwError(errorMsg);
  }

  /**
   * @description get stock by local storage info
   * @param id subsidiary id
   * @param param total or by sucursal
   */
  public getSubsidiaryStock(id: string, param: string) {
    param = param.trim();

    // Add safe, URL encoded search parameter
    const options = param
      ? { params: new HttpParams().set('attribute', param) }
      : {};

    return this.http
      .get<any>(SUBSIDIARY_STOCK + id, options)
      .pipe(catchError(this.handleError));
  }

  /**
   * @description Get abstract stock
   * @param param sucursal or total
   */
  public getAbstractStock(param: string) {
    param = param.trim();

    // Add safe, URL encoded search parameter
    const options = param
      ? { params: new HttpParams().set('attribute', param) }
      : {};

    return this.http.get<any>(ALL_STOCK_URL, options).pipe(
      catchError(this.handleError), // then handle error
    );
  }

  public getAbstractSub(
    parameters: { kind: string; attribute: string },
    id: string,
  ) {
    // Initialize params object
    const params = new HttpParams({ fromObject: parameters });

    const options = {
      params,
    };
    return this.http.get<any>(SUBSIDIARY + id, options).pipe(
      catchError(this.handleError), // then handle error
    );
  }

  public getCodeSub(
    parameters: { kind: string; attribute: string },
    id: string,
  ) {
    // Initialize params object
    const params = new HttpParams({ fromObject: parameters });

    const options = {
      params,
    };
    return this.http.get<any>(CODE + id, options).pipe(
      catchError(this.handleError), // then handle error
    );
  }

  /**
   * @description Get stock in subsidiary by client status
   * @param id id subsidiary
   * @param parameters kind of status
   */
  public getStockInSubsByStatus(id: string, parameters: any) {
    // Initialize params object
    const params = new HttpParams({ fromObject: parameters });

    const options = {
      params,
    };

    return this.http
      .get<any>(STATUS_STOCK + id, options)
      .pipe(catchError(this.handleError));
  }

  /**
   * @description Get stock in subsidiary by internal status
   * @param id id subsidiary
   * @param parameters kind of status
   */
  public getStockInternalByStatus(id: string, parameters: any) {
    // Initialize params object
    const params = new HttpParams({ fromObject: parameters });

    const options = {
      params,
    };

    return this.http
      .get<any>(STATUSINTERNAL_STOCK + id, options)
      .pipe(catchError(this.handleError));
  }
}
