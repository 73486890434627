//? *************************** DEPENDENCIES **********************************/
import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  //? **************************** LIFECYCLE ***********************************/

  constructor() {}

  //? *************************** FUNCTIONS ************************************/

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler,
  ): Observable<HttpEvent<any>> {
    // Get the auth token from the service.
    const authToken: string = localStorage.getItem('token');

    // Clone the request and replace the original headers with
    // cloned headers, updated with the authorization
    if (authToken) {
      const authReq = req.clone({
        setHeaders: { Authorization: 'Bearer ' + authToken },
      });
      // send cloned request with header to the next handler.
      return next.handle(authReq);
    }
    return next.handle(req);
  }
}
