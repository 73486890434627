//? *************************** DEPENDENCIES ***********************************/

import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

//? ************************ LOCAL DEPENDENCIES ********************************/
import { AdminComponent } from './theme/layout/admin/admin.component';
import { AuthComponent } from './theme/layout/auth/auth.component';
import { TokenValidationGuard } from './guards/token-validation.guard';

const routes: Routes = [
  {
    path: 'home',
    component: AdminComponent,
    canActivate: [TokenValidationGuard],
    canLoad: [TokenValidationGuard],
    loadChildren: () =>
      import('./pages/home/home.module').then((module) => module.HomeModule),
  },
  {
    path: 'catalog',
    component: AdminComponent,
    canActivate: [TokenValidationGuard],
    canLoad: [TokenValidationGuard],
    loadChildren: () =>
      import('./pages/catalogs/catalogs.module').then(
        (module) => module.CatalogsModule,
      ),
  },
  {
    path: 'asset',
    component: AdminComponent,
    canActivate: [TokenValidationGuard],
    canLoad: [TokenValidationGuard],
    loadChildren: () =>
      import('./pages/assets/assets.module').then(
        (module) => module.AssetsModule,
      ),
  },
  {
    path: 'logistic',
    component: AdminComponent,
    canActivate: [TokenValidationGuard],
    canLoad: [TokenValidationGuard],
    loadChildren: () =>
      import('./pages/logistic/logistic.module').then(
        (module) => module.LogisticModule,
      ),
  },
  {
    path: 'technical-service',
    component: AdminComponent,
    canActivate: [TokenValidationGuard],
    canLoad: [TokenValidationGuard],
    loadChildren: () =>
      import('./pages/technical-service/technical-service.module').then(
        (module) => module.TechnicalServicesModule,
      ),
  },
  {
    path: 'auth',
    component: AdminComponent,
    canActivate: [TokenValidationGuard],
    canLoad: [TokenValidationGuard],
    loadChildren: () =>
      import('./pages/auth/auth.module').then((module) => module.AuthModule),
  },
  {
    path: 'login',
    component: AuthComponent,
    loadChildren: () =>
      import('./pages/login/login.module').then((module) => module.LoginModule),
  },
  {
    path: 'service-repair',
    component: AuthComponent,
    loadChildren: () =>
      import('./pages/repair-service/repair-service.module').then(
        (module) => module.RepairServiceModule,
      ),
  },
  {
    path: '',
    pathMatch: 'full',
    redirectTo: 'home',
  },
  {
    path: '**',
    loadChildren: () =>
      import('./pages/not-found/not-found.module').then(
        (module) => module.NotFoundModule,
      ),
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      useHash: true,
      anchorScrolling: 'enabled',
      onSameUrlNavigation: 'reload',
      scrollPositionRestoration: 'enabled',
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
