export const messageError: Record<string, string> = {
  //?   Authentication
  'AU-001': 'Nombre de usuario incorrecto',
  'AU-002': 'No existen usuarios registrados en la base de datos',
  'AU-003': 'No existe sucursal con el identificador recibido',
  'AU-004': 'Los campos nombre y apellido_paterno son obligatorios',
  'AU-005': 'El campo usuario es obligatorio',
  'AU-006': 'Los campos Nombre de usuario, email y contraseña son obligatorios',
  'AU-007': 'El campo email no tiene un formato valido de correo electronico',
  'AU-008': 'La longitud minima para el campo contraseña es de 6 caracteres',
  'AU-009': 'El email proporcionado ya se encuentra registrado en el sistema',
  'AU-010': `El nombre de usuario proporcionado ya se encuentra registrado en el sistema`,
  'AU-011': `No existe un Perfil que corresponda con los parametros de búsqueda`,
  'AU-012': 'No existen perfiles registrados en la base de datos',
  'AU-013': 'El campo nombre de usuario es obligatorio',
  'AU-014': 'El campo contraseña es obligatorio',
  'AU-015': 'La contraseña es incorrecta',
  'AU-016': 'Token incorrecto',
  'AU-017': 'Método no permitido',
  //?   Management
  'MG-001': 'No se ha ingresado un ID valido en la consulta',
  'MG-002': 'No hay estatus Cliente en la base de datos',
  'MG-003': 'No se encontro estatus interno con el ID ingresado',
  'MG-004': 'Se actualizo la colección principal pero no la del activo',
  'MG-005': 'El parametro ID debe ser un ID válido de MongoDB',
  'MG-006': 'La colección es un parámetro requerido en la URL',
  'MG-007': 'No existe un flujo de trabajo definido para el módulo de gestión',
  'MG-008': `La colección a la que se está tratando de acceder no está definida en el flujo de trabajo`,
  'MG-009': 'Error en el flujo de trabajo',
  'MG-010': 'Error en el objeto enviado',
  'MG-011': 'No se encontraron elementos con los parametros de búsqueda',
  'MG-012': 'El token de autenticacion es obligatorio en la consulta',
  'MG-013': 'Metodo no permitido',
  'MG-014': `El flujo seleccionado no cuenta con los campos recibidos en la solicitud  tipo GET`,
  'MG-015': `El usuario no tiene los permisos necesarios para realizar esta acción`,
  'MG-016': `No hay resultado encontrado con los campos enviados en la colección seleccionada`,
  'MG-017': `El flujo seleccionado no cuenta con los campos recibidos en la solicitud tipo POST`,
  'MG-018': 'El id del usuario no existe en la base de datos',
  'MG-019': 'El elemento buscado no se encuentra en la base de datos',
  'MG-020': `El flujo seleccionado no cuenta con los campos recibidos en la solicitud tipo PUT`,
  //?   Logistic
  'LG-001': 'Problema con el flujo de trabajo de logística',
  'LG-002': 'Problema con los campos o parámetros enviados',
  'LG-003': 'Un equipo no puede entrar',
  'LG-004': `Un equipo no puede salir o no se encuentra en la sucursal seleccionada`,
  'LG-005': 'Un equipo buscado por identificador de sistema no existe',
  'LG-006': 'Se encontró un equipo repetido',
  'LG-007': 'Destino no encontrado',
  'LG-008': 'Origen no encontrado',
  'LG-009': `La persona seleccionada como operador no tiene un vehiculo asignado`,
  'LG-010': `Hubo un error al generar el servicio técnico`,
  'LG-011': 'La sucursal es requerida cuando el usurio no tiene una definida',
  'LG-012': 'La sucursal no es requerida cuando el usuario tiene una sucursal',
  'LG-013': 'La sucursal es requerida cuando el usuario no tiene sucursal',
  'LG-014': 'El origen y el destino son el mismo',
  'LG-015': 'El usuario no tiene permisos para acceder al Flujo Logistico',
  'LG-016': 'No existe un Flujo Logistico definido para la sucursal',
  'LG-017': 'Ya existe un Flujo Logistico definido para la sucursal',
  'LG-018': 'El Id recibido no tiene una sucursal asociada',
  'LG-019': 'El campo sucursal es requerido',
  'LG-020': 'Método no permitido',
  'LG-021': `El usuario no tiene los permisos necesarios para realizar esta acción`,
  'LG-022': 'El parametro de autorización es necesario en los encabezados',
  'LG-023': 'El id del usuario no existe en la base de datos',
  'LG-024': `No se encontraron Entradas que coincidan con los parametros de búsqueda`,
  'LG-025': `No se encontraron Salidas que coincidan con los parametros de búsqueda`,
  //?  Warehouse
  'WH-001': 'Los parametros id y economico no pueden ser enviados juntos',
  'WH-002':
    'Los parametros no. de serie y economico no pueden ser enviados juntos',
  'WH-003': 'Los parametros id y no. de serie no pueden ser enviados juntos',
  'WH-004':
    'Los parametros id, economico y no. de serie no pueden ser enviados juntos',
  'WH-005': 'Uno de los parametros id, economico o no. de serie es obligatorio',
  'WH-006': 'No existen activos que coincidan con los parametros de búsqueda',
  'WH-007':
    'Para crear un activo son necesarios que al menos exista economico o no. de serie',
  'WH-008': 'El economico proporcionado ya existe en la base de datos',
  'WH-009': 'El número de serie proporcionado ya existe en la base de datos',
  'WH-010': '',
  'WH-011': '',
  //? TS
  'TS-001':
    'Se debe enviar algún parámetro para buscar el cabinet, ya sea el económico, la serie o el identificador de la base de datos. ',
  'TS-002': 'Solo se debe enviar un parámetro para buscar el cabinet ',
  'TS-003': 'No se encontró el cabinet ',
  'TS-004': 'No se encontró un servicio relacionado al refrigerador.',
  'TS-005':
    'No se encontró un flujo de trabajo relacionado a la sucursal o udn.',
  'TS-006': 'La etapa actual no se encontró dentro del flujo de trabajo',
  'TS-007':
    'El identificador del usuario es requerido para atender el servicio.',
  'TS-008':
    'Uno de los insumos enviados no es utilizable para este equipo en esta etapa.',
  'TS-009':
    'Uno de los insumos recuperados no se encuentra en la base de datos.',
  'TS-010': 'El flujo de trabajo está mal definido.',
  'TS-011':
    'El flujo siguiente es requerido cuando la etapa es un punto de decisión.',
  'TS-012':
    'Una etapa final no puede tener etapa siguiente ni flujo siguiente.',
  'TS-013': 'Servicio finalizado.',
  'TS-014': 'El cabinet ya salió pero tiene un servicio abierto.',
  'TS-015': 'Uno o mas refacciones no son recuperables.',
  'TS-016': 'Una o mas acciones enviadas no son válidas para la etapa actual',
  'TS-017': 'Parámetros inválidos enviados para la búsqueda de servicios',
  'TS-018': 'No se encontró ningún servicio con los parámetros proporcionados',
  'TS-019':
    'No se pueden enviar los parámetros error, flow o stage si no se va a cerrar la etapa',
  'TS-020': 'El identificador de persona enviado no es válido',
  'TS-021': 'El estatus indicado en el catálogo de etapa no es válido ',
  'TS-022':
    'No se debe enviar la sucursal cuando el usuario tiene una definida',
  'TS-023': 'La sucursal es requerida cuando el usuario no tiene una definida',
};
