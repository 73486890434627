
import { HttpErrorResponse, HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { catchError } from 'rxjs/operators';
import { throwError, Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { Asset } from '../../interfaces';
import { routes } from '../../constants';

const fridgeURL = environment.apiUrl + routes.FridgeURL;
const fridgesURL = environment.apiUrl + '/warehouse/fridges/';
const assetURL = environment.apiUrl + routes.AssetURL;
const fridgeStatusURL = environment.apiUrl + routes.FridgeStatusURL;

@Injectable()
export class FridgeService {
    constructor(private http: HttpClient) { }

    private handleError(error: HttpErrorResponse) {
        let errorMsg;
        if (error.error instanceof ErrorEvent) {
            // A client-side or network error ocurred. Handle it acording to rules.
        } else {
            // The backend returned an unsuccessful response code.
        }
        if (error.error) {
            if (error.error.code) {
                errorMsg = error.error.code;
            } else {
                errorMsg = error.error;
            }
            console.log(error);
        }
        return throwError(errorMsg);
    }

    /** API: GET /fridge */
    public getFridgeByEco(id: string) {
        return this.http.get<Asset>(fridgeURL + id)
            .pipe(
                catchError(this.handleError) // then handle error
            );
    }

    public getFridges(assets) {
        const query = assets.map( (el, idx) => {
            return `assets[${idx}]=${el}`;
        }).join('&');

        let params = new HttpParams();
        params = params.set('assets', assets);

        const options = {
            params
        };
        return this.http.get<any>(fridgesURL + query)
            .pipe(
                catchError(this.handleError)
            );
    }

    /** API: PATCH /fridge */
    public updateFridgeByEco(id: string, fridge: Asset): Observable<Asset> {
        return this.http.put<Asset>(fridgeStatusURL + id, fridge)
            .pipe(
                catchError(this.handleError)
            );
    }

    public getAsset(parameters: any) {

        // Initialize params object
        const params = new HttpParams({fromObject: parameters});

        const options = {
            params
        };

        return this.http.get<any>(assetURL, options )
        .pipe(
            catchError(this.handleError));

    }

    public postAssets(asset: any) {

        // Initialize params object
        return this.http.post<any>(fridgeURL, asset)
        .pipe(
            catchError(this.handleError));

    }

}
