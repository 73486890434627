<div class="error-page">
    <div>
        <h1 data-h1="403">403</h1>
        <p data-p="No se encontraron los permisos para acceder a esta página">No se encontraron los permisos para
            acceder a esta página</p>
    </div>
</div>
<br>
<div class="text-center">
    <button class="btn waves-effect waves-light btn-primary mb-4"  (click)="tohome()"><i class="feather icon-home mr-2"></i>Volver al
        inicio</button>
</div>