<div class="text-center">
    <img src="assets/images/maintenance/maintance.png" alt="" class="img-fluid" />
    <h3 class="text-muted my-4">
        El usuario no cuenta con sucursales asociadas
    </h3>
</div>
<div class="text-center">
    <button class="btn waves-effect waves-light btn-primary mb-4" (click)="tohome()"><i
            class="feather icon-home mr-2"></i>Volver al
        inicio</button>
</div>