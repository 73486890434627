//? *************************** DEPENDENCIES ***********************************/

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

//? ************************ LOCAL DEPENDENCIES ********************************/

import { SuspensivePipe } from './pipes/suspensive.pipe';

@NgModule({
  declarations: [SuspensivePipe],
  imports: [CommonModule],
  exports: [SuspensivePipe],
})
export class MainPipe {}
