<perfect-scrollbar
  *ngIf="this.flatConfig['layout'] === 'vertical'"
  (clickOutside)="navMob()"
  [excludeBeforeClick]="true"
  [exclude]="'#mobile-collapse'"
  id="nav-ps-flat-able"
  ngClass="flat-able-scroll"
  [style.max-width]="'300px'"
  [style.max-height]="'calc(100vh - 56px)'"
  [usePSClass]="'flat-able'"
  [disabled]="null"
>
  <div class="navbar-content">
    <div class="">
      <div class="main-menu-header">
        <ng-container>
          <img
            class="img-radius"
            [src]="user.foto"
            alt="User-Profile-Image"
            onerror="this.onerror=null;this.src='./../../../assets/images/user.png'"
          />
          <div class="user-details">
            <span>{{ user.nombre }} {{ user.apellido_materno }}</span>
          </div>
        </ng-container>
      </div>
    </div>
    <ul class="nav pcoded-inner-navbar" (clickOutside)="fireOutClick()">
      <ng-container *ngFor="let item of navigation">
        <app-nav-group
          *ngIf="item.type == 'group'"
          [item]="item"
        ></app-nav-group>
        <app-nav-collapse
          *ngIf="item.type == 'collapse'"
          [item]="item"
        ></app-nav-collapse>
        <app-nav-item *ngIf="item.type == 'item'" [item]="item"></app-nav-item>
      </ng-container>
    </ul>
  </div>
</perfect-scrollbar>

<div
  *ngIf="this.flatConfig['layout'] === 'horizontal'"
  #navbarWrapper
  class="navbar-content sidenav-horizontal"
  id="layout-sidenav"
>
  <a
    href="javascript:"
    class="sidenav-horizontal-prev"
    [ngClass]="prevDisabled"
    (click)="scrollMinus()"
  ></a>
  <div class="sidenav-horizontal-wrapper">
    <!-- add 14-4 viral -->
    <ul
      #navbarContent
      id="side-nav-horizontal"
      class="nav pcoded-inner-navbar sidenav-inner"
      (clickOutside)="fireLeave()"
      (mouseleave)="fireLeave()"
    >
      <ng-container *ngFor="let item of navigation">
        <app-nav-group
          *ngIf="item.type == 'group'"
          [item]="item"
        ></app-nav-group>
        <app-nav-collapse
          *ngIf="item.type == 'collapse'"
          [item]="item"
        ></app-nav-collapse>
        <app-nav-item *ngIf="item.type == 'item'" [item]="item"></app-nav-item>
      </ng-container>
    </ul>
  </div>
  <a
    href="javascript:"
    class="sidenav-horizontal-next"
    [ngClass]="nextDisabled"
    (click)="scrollPlus()"
  ></a>
</div>
