/** WAREHOUSE */
export const WAREHOUSE_PERMISSIONS = [
  'warehouse__fridge__dashboard',
  'warehouse__fridge__search',
  'warehouse__fridge__stock',
];
export const INVENTORY_PERMISSIONS = [];
/** AUTHENTICATION */
export const AUTH_PERMISSIONS = [
  'profile__creation__module',
  'permissions__show__module',
  'profile__show__module',
  'users__creation__module',
  'users__show__module',
];
export const CATALOG_PERMISSIONS = [
  'show__actions__module',
  'show__agencies__module',
  'show__assetModel__module',
  'show__clients__module',
  'show__equipmentkinds__module',
  'show__fridgebrands__module',
  'show__internalStatus__module',
  'show__logisticWorkflow__module',
  'show__productionWorkflow__module',
  'show__stages__module',
  'show__status__module',
  'show__subsidiaries__module',
  'show__transportDriver__module',
  'show__transportKind__module',
  'show__transportLine__module',
];
export const LOGISTIC_PERMISSIONS = [
  'logistic__service__arrival',
  'logistic__service__departure',
  'logistic__service__registry',
  'logistic__service__traceability',
];
export const TECHNICAL_PERMISSIONS = [
  'technical__service__kardex',
  'technical__service__reports',
];
/** ALL PERMISSIONS */
export const ALL_PERMISSIONS = [
  ...WAREHOUSE_PERMISSIONS,
  ...INVENTORY_PERMISSIONS,
  ...AUTH_PERMISSIONS,
  ...CATALOG_PERMISSIONS,
  ...LOGISTIC_PERMISSIONS,
  ...TECHNICAL_PERMISSIONS,
];
