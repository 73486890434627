// TODO: Refresh token

//? *************************** DEPENDENCIES **********************************/

import { Injectable } from '@angular/core';
import { CanActivate, CanLoad, Router } from '@angular/router';
import { Observable, of } from 'rxjs';
import { map, tap } from 'rxjs/operators';

//? **************************** SERVICES *************************************/
import { AuthService } from '../services/auth.service';

@Injectable({
  providedIn: 'root',
})
export class TokenValidationGuard implements CanActivate, CanLoad {
  //? **************************** LIFECYCLE **********************************/

  constructor(private _authService: AuthService, private _router: Router) {}

  //? *************************** FUNCTIONS ************************************/

  canActivate(): Observable<boolean> | boolean {
    return this._authService.tokenValidation().pipe(
      tap((valid) => this.checkValid(!!valid)),
      map(() => true),
    );
  }

  canLoad(): Observable<boolean> | boolean {
    const token = localStorage.getItem('token');
    if (token) {
      return this._authService.tokenValidation().pipe(
        tap((valid) => this.checkValid(!!valid)),
        map(() => true),
      );
    }
    this.checkValid(false);
  }

  checkValid = (token: boolean): void => {
    if (!token) this._router.navigateByUrl('/login');
  };
}
