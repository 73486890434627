<ul class="navbar-nav ml-auto">
    <li>
        <div class="drp-user dropdown" ngbDropdown placement="auto">
            <a href="javascript:" ngbDropdownToggle>
                <i class="feather icon-user"></i>
            </a>
            <div class=" dropdown-menu-right profile-notification" ngbDropdownMenu>
                <div class="pro-head hover-head" (click)="logout()" href="javascript:">
                    Cerrar Sesión
                    <a class="dud-logout" title="Logout">
                        <i class="feather icon-log-out"></i>
                    </a>
                </div>
            </div>
        </div>
    </li>
</ul>