export const routes: Record<string, string> = {
  /****************************** AUTHENTICATION ***********************************/
  LOGIN__URL: '/authentication/login',
  PROFILE__URL: '/authentication/profile/',
  REFRESH__TOKEN__URL: '/authentication/refreshToken',
  USER__URL: '/authentication/user/',
  /****************************** MANAGEMENT ***********************************/
  GENERIC__URL: '/management/catalogues',

  UserURL: '/authentication/management/person',

  /** Inventory */
  StorehouseURL: '/inventory/storehouse',
  StorehouseKindURL: '/inventory/storehousekind',
  SupplyURL: '/inventory/supply',
  SupplyKindURL: '/inventory/supplykind',
  UnitsURL: '/inventory/unit',

  /** Logistic */
  ArrivalURL: '/logistics/arrivals/crud',
  DepartureURL: '/logistics/departures/crud',
  LogisticOverviewURL: '/logistics/reports/overview',
  WorkflowURL: '/logistics/catalogues/workflow',
  OriginURL: '/logistics/catalogues/origins',
  DestinationURL: '/logistics/catalogues/destinations',

  /** Management */
  AgencyURL: '/management/catalogues/agency/',
  GenericURL: '/management/catalogues',
  SubsidiaryURL: '/management/catalogues/subsidiary/',

  /** Technical Service */
  ACTION__URL: '/technical-service/catalogues/action/',
  STAGE__URL: '/technical-service/catalogues/stage/',
  ActionURL: '/technical-service/catalogues/action/',
  HISTORICAL: '/technical-service/reports/historical',
  HistoricalURL: '/technical-service/reports/historical?',
  ServiceURL: '/technical-service/service/actual-service',
  StageURL: '/technical-service/catalogues/stage/',

  /** Warehouse */
  AbstractStock: '/warehouse/fridge/abstractstock/',
  AllStockURL: '/warehouse/fridge/allstock',
  AssetURL: '/warehouse/assets',
  CodeURL: '/warehouse/fridge/codestock/',
  FridgeURL: '/warehouse/fridge/',
  FridgeStatusURL: '/warehouse/fridgebystatus/',
  InternalStatusStock: '/warehouse/fridge/internalstock/',
  StatusStock: '/warehouse/fridge/statusstock/',
  StockURL: '/warehouse/fridge/stock/',
};
Object.seal(routes);
