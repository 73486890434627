export const TABLESETTINGS = {
  decimal: '',
  emptyTable: 'No existen resultados',
  info: 'Mostrando _START_ a _END_ de _TOTAL_ registros',
  infoEmpty: 'Mostrando 0 a 0 de 0 registros',
  infoFiltered: '(filtrado desde _MAX_ registros totales)',
  infoPostFix: '',
  thousands: ',',
  lengthMenu: 'Muestra _MENU_ registros',
  loadingRecords: 'Cargando ...',
  processing: 'Procesando ...',
  search: 'Búsqueda:',
  zeroRecords: 'No se encontraron registros',
  paginate: {
    first: 'Primera Página',
    last: 'Última Página',
    next: 'Siguiente',
    previous: 'Anterior',
  },
  aria: {
    sortAscending: ': activa para ordenar las columnas de manera ascendente',
    sortDescending: ': acttiva para ordenar las columnas de manera descendente',
  },
};

export const TableSettings = {
  decimal: '',
  emptyTable: 'No existen resultados',
  info: 'Mostrando _START_ a _END_ de _TOTAL_ registros',
  infoEmpty: 'Mostrando 0 a 0 de 0 registros',
  infoFiltered: '(filtrado desde _MAX_ registros totales)',
  infoPostFix: '',
  thousands: ',',
  lengthMenu: 'Muestra _MENU_ registros',
  loadingRecords: 'Cargando ...',
  processing: 'Procesando ...',
  search: 'Búsqueda:',
  zeroRecords: 'No se encontraron registros',
  paginate: {
    first: 'Primera Página',
    last: 'Última Página',
    next: 'Siguiente',
    previous: 'Anterior',
  },
  aria: {
    sortAscending: ': activa para ordenar las columnas de manera ascendente',
    sortDescending: ': acttiva para ordenar las columnas de manera descendente',
  },
};
